<template>
  <div id="app" :key="key">
    <Header :logo="articleDetail.logo" />
    <div class="app-content">
      <router-view />
      <Information />
    </div>
    <Footer :info="articleDetail" />
  </div>
</template>

<script>
import Footer from '@/components/basic/footer'
import Header from '@/components/basic/header'
import Information from '@/components/basic/information'
import {system} from '@/api/common/public'
export default {
  name: 'App',
  components: {
    Footer,
    Header,
    Information
  },
  data() {
    return {
      articleDetail: {}
    }
  },
  methods: {
    systemModel() {
      const data = {
        key: 'logo,phone,wechat,mini_code,email,address'
      }
      system(data).then(res => {
        this.articleDetail = res.data
      })
    }
  },
  computed: {
    key() {
      return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
    }
  },
  mounted() {
    if (this.$route.path !== '/login') {
      localStorage.removeItem('loginType')
    }
    this.systemModel()
  }
}
</script>

<style lang="scss">
  html, body {
    height: 100%;
  }
  body {
    margin: 0;
    background: #F7F9FC;
  }
  p{
    img{
      max-width: 100%;
      height: auto;
    }
  }
  #app {
    min-height:100%;
    display: flex;
    flex-direction: column;
    font-family: Source Han Sans CN;
    color: #333;
    font-size: 18px;
    // background-color: $page-background-color;
  }
  .app-content{
    margin-top: 80px;
    flex: 1;
  }
  .flex{
    display: flex;
  }
  .flex_ac{
    display: flex;
    align-items: center;
  }
  .flex_jc{
    display: flex;
    justify-content: center;
  }
  .flex_jcsa{
    display: flex;
    justify-content: space-around;
  }
  .flex_jc_ac{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex_jcsb{
    display: flex;
    justify-content: space-between;
  }
  .flex_jcsb_ac{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ellipsis-1{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .ellipsis-row{
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .el-button--primary{
    background-color: #1166CF;
    &:hover{
      background: #2173D8;
    }
  }
  .el-button--primary.is-plain{
    background: #fff;
    border-color: #1166CF;
    &:hover{
      background: #2173D8;
    }
  }
  .participateInBtn{
    border: none;
    color: #333;
    background: #F9D900;
    &:hover{
      color: #333;
      background: #F9BB00;
    }
  }
  .cursor{
    cursor: pointer;
  }
  .mouse{
    cursor: pointer;
    &:hover{
      border: 1px solid #1166CF;
    }
  }
  .buttomBtn {
    width: 320px;
    height: 42px;
    background: #FFDB25;
    border: 1px solid #FFDB25;
    margin-top: 40px;
    color: #333;
    font-size: 16px;
    &:hover{
      color: #333;
      border: 1px solid #FFE425;
      background: #FFE425;
    }
  }
  .indivualText{
    width: 18px;
    height: 18px;
    background: #449CED;
    border-radius: 50%;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    text-align: center;
    margin-left: 12px;
  }
  .enterpriseText{
    width: 18px;
    height: 18px;
    background: #FFB400;
    border-radius: 50%;
    line-height: 18px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-left: 12px;
  }
  /*
  * 清除标签默认样式
  */
  ul,li {
    padding:0;
    margin:0;
    list-style:none
  }
  /*包含以下四种的链接*/
  a {
    text-decoration: none;
  }
  /*正常的未被访问过的链接*/
  a:link {
    text-decoration: none;
  }
  /*已经访问过的链接*/
  a:visited {
    text-decoration: none;
  }
  /*鼠标划过(停留)的链接*/
  a:hover {
    text-decoration: none;
  }
  /* 正在点击的链接*/
  a:active {
    text-decoration: none;
  }
</style>
