import request from '@/utils/request'

export function FormUploadImg(data) { // 13.上传文件
  return request({
    url: 'Upload/FormUploadt',
    method: 'post',
    data
  })
}
export function PerfectInfo(data) { // 15.认证接口 14.完善信息
  return request({
    url: 'User/PerfectInfo',
    method: 'post',
    data
  })
}
export function myInfo(data) { // 16.获取我的信息
  return request({
    url: 'User/my',
    method: 'post',
    data
  })
}
export function create(data) { // 17.发布项目
  return request({
    url: 'Project/create',
    method: 'post',
    data
  })
}
export function statistics(data) { // 37.我的页面统计
  return request({
    url: 'User/statistics',
    method: 'post',
    data
  })
}
export function myProject(data) { // 34.我的发布项目列表
  return request({
    url: 'Project/myList',
    method: 'post',
    data
  })
}
export function myTenderList(data) { // 36.我的投标列表
  return request({
    url: 'Tender/myTenderList',
    method: 'post',
    data
  })
}
export function followList(data) { // 35.关注列表
  return request({
    url: 'Project/followList',
    method: 'post',
    data
  })
}
export function evaluationList(data) { // 20.我的评价列表
  return request({
    url: 'Evaluation/index',
    method: 'post',
    data
  })
}
export function feedback(data) { // 40.意见反馈
  return request({
    url: 'Feedback/create',
    method: 'post',
    data
  })
}
export function MesList(data) { // 38.消息列表
  return request({
    url: 'Mes/index',
    method: 'post',
    data
  })
}
export function MesDelete(data) { // 39.删除消息
  return request({
    url: 'Mes/delete',
    method: 'post',
    data
  })
}
