<template>
  <div class="content">
    <el-dialog
      :visible.sync="dialogVisible"
      :width="modalWidth"
      @close="modalClone"
      :show-close="false">
      <div slot="title" class="title">
        <span>{{ modalTitle }}</span>
      </div>
      <div class="close" @click="modalClone">
        <!-- <div class="icon iconfont icon-guanbi"></div> -->
        <i class="el-icon-circle-close icon"></i>
        <div class="line"></div>
      </div>
      <slot name="modalContent"></slot>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
    modalWidth: {
      type: String,
      default: '300px'
    },
    modalTitle: {
      type: String,
      default: '标题'
    }
  },
  name: 'modal',
  data() {
    return {
      dialogVisible: false // 弹框显示
    }
  },
  watch: {
    modalShow: function (newVal) {
      this.dialogVisible = newVal;
    }
  },
  methods: {
    modalClone() { // 关闭弹框组件
      this.$emit('modalClone');
    }
  }
}
</script>

<style lang="scss" scoped>
   .content {
     /deep/ {
      .el-dialog__body {
        padding: 0 20px;
      }
      .el-dialog__header{
        padding: 20px 40px 10px;
      }
     }
  }
  .title {
    text-align: left;
    font-size: 17px;
    font-weight: bold;
    color: #333;
    position: relative;
    span {
      position: relative;
    }
    span:after {
      content: "";
      width: 100%;
      height: 6px;
      background-color: #0078FF;
      opacity: .2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .close {
    position: absolute;
    width: 26px;
    top: -60px;
    right: 12px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .line {
      width: 2px;
      height: 33px;
      margin-top: -1px;
      opacity: .5;
      background-color: #fff;
    }
    .icon {
      font-size: 26px;
      color: #fff;
      cursor: pointer;
    }
    .icon:hover {
      color: #FFDB25;
    }
  }
</style>
