<template>
  <div v-if="touCount">
    <div class="information flex" @click="isevaluateModal = true">
      <div class="hint"></div>
      <div class="text">我的消息</div>
    </div>
    <Modal modalTitle="我的消息" modalWidth="800px" :modalShow="isevaluateModal" @modalClone="isevaluatemodalClone">
      <el-carousel height="650px" slot="modalContent" :autoplay="false">
        <el-carousel-item v-for="(item, index) in informationList" :key="index" class="paddingWei">
          <div class="evaluate-center flex_jcsb_ac" v-for="(items,indexs) in item" :key="indexs">
            <div class="audit">
              <div class="flex_jcsb_ac">
                <div class="title">{{items.name}}</div>
                <div class="tiem">{{items.create_time}}</div>
              </div>
              <div class="conent">{{items.content}}</div>
            </div>
            <div class="flex_jc_ac delete" @click="deleteClic(items.id)">
              <img src="../../assets/images/icon/shanchu.png" alt="" />
              <span>删除</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </Modal>
  </div>
</template>

<script>
import {MesList, MesDelete} from '@/api/user/user'
import Modal from '@/components/basic/modal'
export default {
  name: 'information',
  components: {
    Modal
  },
  data() {
    return {
      touCount: '',
      informationList: [],
      isevaluateModal: false
    }
  },
  mounted() {
    if (localStorage.getItem('userInfo')) {
      this.informationModel()
    }
  },
  methods: {
    isevaluatemodalClone() { // 关闭评价弹框
      this.isevaluateModal = false;
    },
    deleteClic(id) {
      const data = {
        id
      }
      MesDelete(data).then(res => {
        if (res.code === 1) {
          this.$message.success('删除成功')
          this.informationList = []
          this.informationModel()
        } else {
          this.$message.success(res.mes)
        }
      })
    },
    informationModel() {
      const data = {
        psize: 1000000,
        page: this.page
      }
      MesList(data).then(res => {
        this.touCount = res.data.count
        res.data.list.forEach((item, index) => {
          const page = Math.floor(index / 6)
          if (!this.informationList[page]) {
            this.informationList[page] = []
          }
          this.informationList[page].push(item)
        });
        // console.log(this.informationList);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/{
    .el-carousel__container{
      // height: 420px;
      // width: 100%;
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .el-carousel{
      cursor: pointer;
      .el-carousel__button{
        opacity: 1;
        width: 12px;
        height: 12px;
        background: #EDEFF1;
        border-radius: 50%;
      }
      .el-carousel__indicator.is-active button{
        background: #1166CF;
      }
    }
  }
  .evaluate-center{
    padding: 10px 0;
    .delete{
      width: 80px;
      height: 32px;
      background: #EFF6FF;
      border-radius: 16px;
      margin-left: 16px;
      img{
        // margin-top: 5px;
      }
      span{
        font-size: 14px;
        color: #1166CF;
        margin-left: 3px;
      }
    }
    .audit{
      flex: 1;
      // min-height: 78px;
      background: #F8F8F8;
      border-radius: 8px;
      padding: 20px 15px 17px 15px;
      .title{
        font-size: 16px;
        color: #333333;
      }
      .tiem{
        font-size: 12px;
        color: #666666;
      }
      .conent{
        font-size: 14px;
        color: #666666;
        margin-top: 5px;
      }
    }
  }
  .information{
    position: fixed;
    right: 0;
    top: 250px;
    margin-left:auto;
    background-image: url("../../assets/images/xiaoxi.png");
    background-repeat: no-repeat;
    background-color:transparent ;
    background-repeat: repeat-y;
    background-size: cover;
    width: 120px;
    height: 48px;
    z-index: 9999;
    .hint{
      width: 10px;
      height: 10px;
      background: #FF1324;
      border-radius: 50%;
      margin: 10px 0 0 33px;
    }
    .text{
      font-size: 14px;
      font-family: Microsoft YaHei;
      margin-left: 10px;
      color: #1166CF;
      margin-left: 10px;
      line-height: 48px;
    }
  }
</style>
