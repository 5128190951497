<template>
    <div class="footer">
      <div class="container flex_jcsb">
        <img class="logo" :src="info.logo || '../../assets/images/logo.png'" alt="" />
        <div class="contactUs">
          <div class="item">电话：{{info.phone || '4001-123-456'}}</div>
          <div class="item">邮箱：{{info.email || 'jiumei@163.com'}}</div>
          <div class="item">地址：{{info.address || '上海市普陀区绿地和创大厦'}}</div>
        </div>
        <div class="attention">
          <img :src="info.mini_code || '../../assets/images/er.jpg'" alt="" />
          <div class="applet">小程序</div>
        </div>
      </div>
      <div class="copyright">ICP 备案：<a rel="nofollow" href="https://beian.miit.gov.cn" target="_blank">沪ICP备2022000014号-1</a></div>
    </div>
</template>

<script>
export default {
  name: 'footers',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    background: #fff;
    flex: 0 0 auto;
    position: relative;
    z-index: 100;
    padding-top: 35px;
    font-family: Microsoft YaHei;
    .copyright{
      text-align: center;
      border-top: 1px solid #E4E7EB;
      padding: 16px 0;
      color: #999;
      font-size: 18px;
      margin-top: 20px;
    }
    .container{
      width: 1200px;
      margin: 0 auto;
      .logo{
        width: 154px;
        height: 48px;
      }
      .contactUs{
        .item{
          font-size: 14px;
          margin-bottom: 18px;
        }
      }
      .attention{
        img{
          width: 110px;
          height: 110px;
          background: #F7F9FC;
        }
        .applet{
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
</style>
