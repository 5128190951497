import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ // 页面进度条配置
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})

Vue.use(VueRouter)

const routes = [{ // 主页
		path: '/',
		name: 'index',
		meta:{index:1,title: '首页 | 药精采'},
		component: () => import('@/views/index/index')
	},
	{ // 登录
		path: '/login',
		meta:{index:2,title: '登录 | 药精采'},
		component: () => import('@/views/login/login'),
		name: 'login',
		children: [

		]
	},
	{ // 会员展示
		path: '/memberShow',
		meta:{index:3,title: '会员展示 | 药精采'},
		component: () => import('@/views/memberShow/memberShow'),
		name: 'memberShow',
		children: [

		]
	},
	{ // 会员信息 个人信息
		path: '/memberInformation',
		meta:{index:4,title: '个人信息 | 药精采'},
		component: () => import('@/views/memberInformation/memberInformation'),
		name: 'memberInformation',
		children: [

		]
	},
	{ // 项目搜索
		path: '/projectSearch',
		meta:{index:5,title: '项目搜索 | 药精采'},
		component: () => import('@/views/projectSearch/projectSearch'),
		name: 'projectSearch',
		children: [

		]
	},
	{ // 城市列表
		path: '/cityMap',
		meta:{index:6,title: '城市列表 | 药精采'},
		component: () => import('@/views/cityMap/cityMap'),
		name: 'cityMap',
		children: [

		]
	},
	{ // 项目详情
		path: '/detailsProjesct',
		meta:{index:7,title: '项目详情 | 药精采'},
		component: () => import('@/views/detailsProjesct/detailsProjesct'),
		name: 'detailsProjesct',
		children: [

		]
	},
	{ // 集采
		path: '/collect',
		meta:{index:8,title: '集采 | 药精采'},
		component: () => import('@/views/collect/collect'),
		name: 'collect',
		children: [

		]
	},
	{ // 集采详情
		path: '/collectionDetails',
		meta:{index:9,title: '集采详情 | 药精采'},
		component: () => import('@/views/collectionDetails/collectionDetails'),
		name: 'collectionDetails',
		children: [

		]
	},
	{ // 关于我们
		path: '/aboutUs',
		meta:{index:10,title: '关于我们 | 药精采'},
		component: () => import('@/views/aboutUs/aboutUs'),
		name: 'aboutUs',
		children: [

		]
	},
	{ // 服务内容
		path: '/serviceContent',
		meta:{index:11,title: '服务内容 | 药精采'},
		component: () => import('@/views/serviceContent/serviceContent'),
		name: 'serviceContent',
		children: [

		]
	},
	{ // 用户端
		path: '/user',
		meta:{index:12,title: '用户端 | 药精采'},
		component: () => import('@/views/user/user'),
		name: 'userIndex',
		redirect: '/user/myTracks',
		children: [{ // 用户端-基本信息
				path: 'myTracks',
				name: 'myTracks',
				meta:{index:13,title: '基本信息 | 药精采'},
				component: () => import('@/views/myTracks/myTracks')
			},
			{ // 用户端-发布项目
				path: 'publishProject',
				name: 'publishProject',
				meta:{index:14,title: '发布项目 | 药精采'},
				component: () => import('@/views/publishProject/publishProject')
			},
			{ // 用户端-我的消息
				path: 'myProject',
				name: 'myProject',
				meta:{index:15,title: '我的消息 | 药精采'},
				component: () => import('@/views/myProject/myProject')
			},
			{ // 用户端-我的投标
				path: 'myTender',
				name: 'myTender',
				meta:{index:16,title: '我的投标 | 药精采'},
				component: () => import('@/views/myTender/myTender')
			},
			{ // 用户端-我的关注
				path: 'myFocus',
				name: 'myFocus',
				meta:{index:17,title: '我的关注 | 药精采'},
				component: () => import('@/views/myFocus/myFocus')
			},
			{ // 用户端-我的评价
				path: 'myComment',
				name: 'myComment',
				meta:{index:18,title: '我的评价 | 药精采'},
				component: () => import('@/views/myComment/myComment')
			},
			{ // 用户端-会员中心
				path: 'myMember',
				name: 'myMember',
				meta:{index:19,title: '会员中心 | 药精采'},
				component: () => import('@/views/myMember/myMember')
			},
			{ // 用户端-意见反馈
				path: 'feedback',
				name: 'feedback',
				meta:{index:20,title: '意见反馈 | 药精采'},
				component: () => import('@/views/feedback/feedback')
			},
			{ // 用户端-设置
				path: 'setUp',
				name: 'setUp',
				meta:{index:21,title: '设置 | 药精采'},
				component: () => import('@/views/setUp/setUp')
			}
		]
	}
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
  if (to.meta.auth === false) {
    next()
    return false;
  }
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
  // 进入新的页面组件前，回到页面顶部
  window.scrollTo(0, 0)
})

export default router
