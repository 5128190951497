import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css'
import '@/assets/css/index.scss'
import { Button, Icon, Menu, MenuItem, Input, Select, Option, Carousel, Empty, Form, FormItem, Dialog, Tabs, TabPane,
  CarouselItem, Image, Pagination, Message, Dropdown,  DropdownMenu, DropdownItem, Upload, Cascader, Progress,
  Breadcrumb, BreadcrumbItem, Popconfirm, Rate, Checkbox, Backtop} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import store from './store'
Vue.config.productionTip = false
Vue.prototype.$message = Message
Vue.use(Button).use(Icon).use(Menu).use(MenuItem).use(Input).use(Select)
.use(Option).use(Carousel).use(Empty).use(Form).use(FormItem).use(Dialog)
.use(Tabs).use(TabPane).use(CarouselItem).use(Image).use(Pagination)
.use(Dropdown).use(DropdownMenu).use(DropdownItem).use(Upload).use(Cascader)
.use(Progress).use(Breadcrumb).use(BreadcrumbItem).use(Popconfirm).use(Rate)
.use(Checkbox).use(Backtop)
new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
