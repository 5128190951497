import axios from 'axios'
import { Message } from 'element-ui'
// import store from '@/store'
// import router from '@/router'

// 创建 axios请求实列
const service = axios.create({
  baseURL: '/api',
  timeout: 30000 // 请求时间
})
service.interceptors.request.use( // 请求拦截
  config => {
    config.headers['authorization'] = localStorage.getItem('token') || null; // 设置请求头
    return config;
  }
)
service.interceptors.response.use( // 响应拦截
  res => {
    if (res.data.code === 401) { // 如果接口状态为401则清除token跳转到登录页
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      // 删除本地 token
      localStorage.removeItem('token', res.data.token);
      // 跳转登录
      // router.push({ path: '/login' });
    }
    if (res.data.code === 1) {
      return Promise.resolve(res.data)
    } else {
      Message({
        message: res.data.msg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(res.data)
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default service
