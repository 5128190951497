<template>
  <div
    :class="
      pageHeight ? 'headerContainer opacity' : 'headerContainer opacityBack'
    "
  >
    <div
      :class="
        $route.path !== '/login' ? 'header flex_jcsb_ac' : 'header flex_ac'
      "
    >
      <img
        class="cursor"
        @click="indexClick"
        :src="logo || '../../assets/images/logo.png'"
        alt=""
      />
      <div class="flex_ac" v-if="$route.path !== '/login'">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#333"
          active-text-color="#1166CF"
          @select="handleSelect"
        >
          <el-menu-item
            :index="item.quantity"
            v-for="(item, indexs) in tabsList"
            @click="pageClick(item.quantity)"
            :key="indexs"
            >{{ item.titel }}</el-menu-item
          >
        </el-menu>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="publishProject"
          >发布项目</el-button
        >
        <el-dropdown @command="handleCommand" v-if="userInfo">
          <span class="flex_ac">
            <img
              class="logoImg"
              :src="userInfo.avatarUrl || '../../assets/images/tu.png'"
              alt=""
            />
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link to="/user">个人中心</router-link>
            </el-dropdown-item>
            <el-dropdown-item command="out">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-else>
        <div class="loginPlease" v-if="loginType === '0'">欢迎登录</div>
        <div v-else class="loginPlease">注册</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pageHeight: false, // 控制白色 透明
      userInfo: {},
      loginType: 0, // 判断登录还是注册
      activeIndex: "/",
      tabsList: [
        {
          titel: "首页",
          quantity: "/",
        },
        {
          titel: "项目搜索",
          quantity: "/projectSearch",
        },
        {
          titel: "会员展示",
          quantity: "/memberShow",
        },
        {
          titel: "集采服务",
          quantity: "/collect",
        },
        {
          titel: "会员中心",
          quantity: "/user/myMember",
        },
        {
          titel: "服务内容",
          quantity: "/serviceContent",
        },
        {
          titel: "关于我们",
          quantity: "/aboutUs",
        },
      ],
    };
  },
  methods: {
    indexClick() {
      // 首页
      this.$router.push("/");
      this.activeIndex = "/";
      localStorage.setItem("approverStatus", JSON.stringify(this.activeIndex));
    },
    publishProject() {
      // 发布项目
      if (!this.userInfo) {
        this.$message.warning("请先登录");
        localStorage.setItem("loginType", 0);
        this.$router.push("/login");
        return;
      }
      this.$router.push("/user/publishProject");
    },
    pageClick(page) {
      // 跳转页面
      if (!this.userInfo && page === "/user/myMember") {
        this.$message.warning("请先登录");
        localStorage.setItem("loginType", 0);
        this.$router.push("/login");
        return;
      }
      this.$router.push(page);
    },
    handleScroll() {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop;
      // 当页面滑动的距离大于90px时元素显示，否则不显示
      if (scrollTop >= 90) {
        this.pageHeight = true;
      } else {
        this.pageHeight = false;
      }
    },
    handleSelect(e) {
      this.activeIndex = e;
      localStorage.setItem("approverStatus", JSON.stringify(this.activeIndex));
    },
    handleCommand(type) {
      // 下拉列表点击回调
      if (type === "switchIdentity") {
        this.switchIdentity();
      }
      if (type === "out") {
        this.$router.push("/login");
        localStorage.setItem("loginType", 0);
        localStorage.clear();
      }
    },
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.loginType = localStorage.getItem("loginType");
    this.activeIndex =
      JSON.parse(localStorage.getItem("approverStatus")) || "/";
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("setItemEvent", (e) => {
      this.loginType = localStorage.getItem("loginType");
      this.activeIndex =
        JSON.parse(localStorage.getItem("approverStatus")) || "/";
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    });
  },
  // 组件销毁前
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.opacityBack {
  background-color: rgba(255, 255, 255, 0.6);
}
.opacity {
  background-color: #fff;
}
.headerContainer {
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  .logoImg {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
    background: #ebf1f7;
    margin-left: 24px;
  }
  .header {
    width: 1200px;
    height: 80px;
    margin: 0 auto;
    // background: #fff;
    .loginPlease {
      color: #666;
      font-size: 16px;
      padding-left: 24px;
      margin-left: 24px;
      border-left: 1px solid #dddddd;
    }
  }
  /deep/ {
    .el-menu {
      background: none;
    }
    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }
    .el-menu--horizontal > .el-menu-item {
      font-size: 18px;
      // margin-right: 1px;
      height: 80px;
      line-height: 80px;
    }
    .el-menu--horizontal > .el-menu-item:nth-child(7) {
      margin-right: 23px;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: 5px solid #1166cf;
      // border-radius: 3px;
    }
  }
}
</style>
